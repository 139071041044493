// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { apolloClient } from "../apollo/apollo-client";
import { CheckListFields } from '@shared/enums/shared.enums'
import { LoginService } from '@services/login.service';
import { UPDATE_CHECKLIST } from './apptweakqueries';
import Vue from 'vue';

export function decodeTokenF(token:string): any {
  try {
    return JSON.parse(atob(token.split('.')[1])).userDetails;
  } catch (e) {
    return {};
  }
}

export const updateCLF = (param:string, that: Vue): any => {
  try {
    if(LSS.token && decodeToken(LSS.token).checkList){
      if((decodeToken(LSS.token).checkList[param])) {
        return
      }
    }else{
      return
    }
    apolloClient
      .query({
        query: UPDATE_CHECKLIST,
        variables: {
          userId : decodeToken(LSS.token).UserId,
          clfield: CheckListFields[param],
          time: Date.now()
        }
      })
      .then(async res => {
        //console.log(res.data);
        if(res.data.updateCL){
          if(res.data.updateCL.code == 1){
            await getUserDetails(decodeToken(LSS.token).UserId, that);
          }
        }
      })
      .catch(error => {
        //console.log(error);
      });
  } catch (e) {
    return
  }
}

async function getUserDetails(UserID, that) {
  const token = await LoginService.getUserDetails({
    UserID: UserID,
    SessId: null
  });
  const user = decodeToken(token);
  LSS.token = token
  that.$root.$emit('userNameFetched', user);
  return user;
}
